import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Flex,
    Box,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Globe } from '../assets/Globe';
import Flag from 'react-world-flags';
import { Lang } from '../utils/Enums';
import { CorrectFlag } from '../assets/Flags';
export const LangSelector = () => {
    const { i18n } = useTranslation();
    const selectLang = (langKey: string) => {
        i18n.changeLanguage(langKey);
        window.history.replaceState({}, '', `/${langKey}`);
    };
    return (
        <Menu isLazy placement="auto">
            <MenuButton h="calc(var(--vh) * 8)" w="100%">
                <Flex
                    h="calc(var(--vh) * 8)"
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CorrectFlag langKey={i18n.language}></CorrectFlag>
                </Flex>
            </MenuButton>
            <MenuList minW="4rem">
                <MenuItem onClick={() => selectLang(Lang.Standard)}>
                    <Box
                        h="100%"
                        w="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Globe />
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => selectLang(Lang.Swedish)}>
                    <Box
                        h="100%"
                        w="100%"
                        boxShadow="0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important"
                    >
                        <Flag code={'se'} height="16" />
                        {/* <Flag code={"gb"} height="16" /> */}
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => selectLang(Lang.Norweigan)}>
                    <Box
                        h="100%"
                        w="100%"
                        boxShadow="0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important"
                    >
                        <Flag code={'NO'} height="16" />
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => selectLang(Lang.American)}>
                    <Box
                        h="100%"
                        w="100%"
                        boxShadow="0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important"
                    >
                        <Flag code={'us'} height="16" />
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => selectLang(Lang.Deutch)}>
                    <Box
                        h="100%"
                        w="100%"
                        boxShadow="0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important"
                    >
                        <Flag code={'DEU'} height="16" />
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => selectLang(Lang.Japanese)}>
                    <Box
                        h="100%"
                        w="100%"
                        boxShadow="0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important"
                    >
                        <Flag code={'JPN'} height="16" />
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => selectLang(Lang.French)}>
                    <Box
                        h="100%"
                        w="100%"
                        boxShadow="0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important"
                    >
                        <Flag code={'FRA'} height="16" />
                    </Box>
                </MenuItem>
            </MenuList>
        </Menu>
    );
};
